<script setup lang="ts">
/* eslint import/namespace: ['error', { allowComputed: true }] */
import * as HeroIconsSolid from '@heroicons/vue/20/solid'
import * as HeroIconsOutline from '@heroicons/vue/24/outline'

import { IconName } from '~/types/types'
const props = defineProps<{ icon: IconName; type?: 'outline' | 'solid' }>()
const HeroIcon = computed(() => {
  return props.type === 'outline' ? HeroIconsOutline[props.icon] : HeroIconsSolid[props.icon]
})
</script>
<template>
  <HeroIcon />
</template>
